import Vue from 'vue'
import Vuex from 'vuex'
import {manifestServer} from "../api/config"

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    rightNav: false,
    leftNav: false,
    appBar: false,
    selectedData: null,
    selectedImages: [],
    parcel: {
      weight: {
        status: 'OK'
      },
      type: null,
      size: null,
      manifestTo: {
        full_name: null,
        mobile_no: null,
        address: null,
        addresses: [],
        gcp_addresses: [],
        raw: null,
        scan_time: 0
      },
      manifestToValid: false,
      manifestToImage: null,
      manifestFrom: {
        full_name: null,
        mobile_no: null,
        full_address: null,
        gcp_addresses: [],
        raw: null,
        scan_time: 0,
        addresses: [],
      },
      manifestFromValid: false,
      manifestFromImage: null,
      avgScanTime: null
    },
    parcels: {
      count: 0,
      results: []
    },
    cn22: {},
    cn22s: {},
    search: '',
    alert: null,
    device: {
      name: '',
      token: ''
    },
    devices: [],
    manifestServerAddress: null,
    darkMode: false
  },
  mutations: {
    setDarkMode(state, payload) {
      state.dakrMode = payload
      localStorage.setItem('darkMode', payload)
    },
    initStore(state) {
      let deviceName = localStorage.getItem('deviceName')
      if (deviceName !== 'null') state.device.name = deviceName

      state.darkMode = localStorage.getItem('darkMode') === 'true'

      let manifestServerAddress = localStorage.getItem('manifestServerAddress')
      if (manifestServerAddress !== null) {
        state.manifestServerAddress = manifestServerAddress
        manifestServer.prefixUrl = manifestServerAddress
      } else {
        const serverAddress = 'https://api.skunkworks.ai/v1/graphql'
        localStorage.setItem('manifestServerAddress', serverAddress)
        state.manifestServerAddress = manifestServer.prefixUrl = serverAddress
      }
    },
    setManifestValid(state, payload) {
      state.parcel[payload[0]] = payload[1]
    },
    setManifestServerAddress(state, payload) {
      state.manifestServerAddress = payload
      manifestServer.prefixUrl = payload
      localStorage.setItem('manifestServerAddress', payload)
    },
    setDeviceName(state, payload) {
      state.device.name = payload
      localStorage.setItem('deviceName', payload)
    },
    setDeviceToken(state, payload) {
      state.device.token = payload
      localStorage.setItem('deviceToken', payload)
    },
    setSelectedData(state, payload) {
      Vue.set(state, 'selectedData', payload)
    },
    setState(state, payload) {
      Vue.set(state, payload[0], payload[1])
    },
    setParcelSize(state, payload) {
      state.parcel.size = payload
    },
    setParcelType(state, payload) {
      state.parcel.type = payload
    },
    setParcelWeight(state, payload) {
      state.parcel.weight = payload
    },
    setManifestTo(state, payload) {
      Vue.set(state.parcel, 'manifestTo', payload)
    },
    setManifestToRaw(state, payload) {
      Vue.set(state.parcel, 'manifestToRaw', payload)
    },
    setManifestToImage(state, payload) {
      Vue.set(state.parcel, 'manifestToImage', payload)
    },
    setManifestFrom(state, payload) {
      Vue.set(state.parcel, 'manifestFrom', payload)
    },
    setManifestFromRaw(state, payload) {
      Vue.set(state.parcel, 'manifestFromRaw', payload)
    },
    setManifestFromImage(state, payload) {
      Vue.set(state.parcel, 'manifestFromImage', payload)
    },
    setSelectedImages(state, payload){
      state.selectedImages = payload
    },
    resetParcel(state) {
      state.parcel = {
        weight: {
          status: 'OK'
        },
        type: null,
        size: null,
        manifestTo: {
          full_name: null,
          mobile_no: null,
          address: null,
          addresses: [],
          gcp_addresses: [],
          raw: null,
          scan_time: 0
        },
        manifestToValid: false,
        manifestToImage: null,
        manifestFrom: {
          full_name: null,
          mobile_no: null,
          full_address: null,
          gcp_addresses: [],
          raw: null,
          scan_time: 0,
          addresses: [],
        },
        manifestFromValid: false,
        manifestFromImage: null,
        avgScanTime: null
      }
    }
  },
  actions: {},
  modules: {}
})
