import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'

export const firebaseConfig = {
  apiKey: "AIzaSyBf-rjkjAchnt8bbK_P0wrqMpV0gS5h0_g",
  authDomain: "ace-sector-251421.firebaseapp.com",
  databaseURL: "https://ace-sector-251421.firebaseio.com",
  projectId: "ace-sector-251421",
  storageBucket: "ace-sector-251421.appspot.com",
  messagingSenderId: "101701694836",
  appId: "1:101701694836:web:8ecd75e6dbeca01b512821"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig)
