import ky from "ky"

const DEFAULT_TIMEOUT = 60000 * 5

export let manifestServer = ky.create({
  timeout: DEFAULT_TIMEOUT
})

export let dataServer = ky.create({
  timeout: DEFAULT_TIMEOUT,
  headers: {
    Authorization: 'Token cff124e37e8e8c978b575349149327b0c5204273'
  }
})

export let weighingServer = ky.create({
  prefixUrl: process.env.VUE_APP_CASPD2_API,
  timeout: DEFAULT_TIMEOUT
})

