<template>
  <v-container>
    <s-data-table msg="Welcome to Your Vue.js App"/>
  </v-container>
</template>

<script>
// @ is an alias to /src
import SDataTable from "../components/SDataTable";

export default {
  name: 'Home',
  components: {
    SDataTable,
  }
}
</script>
