<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="20"
    class="elevation-0"
  >
<!--    <template slot="body">
      <tbody>
      <tr v-for="item in items" :key="item.name" @click="select(item)">
        <td>{{ item.name }}</td>
        <td>{{Receiver}}</td>
        <td>Date Added</td>
      </tr>
      </tbody>
    </template>-->
  </v-data-table>
</template>

<script>
export default {
  name: 's-data-table',
  props: [
    'type',
    'items',
    'headers'
  ],
  methods: {
    select(item) {
      let data = {
        value: item,
        type: this.type
      }

      if(this.$store.state.selectedData !== data) {
        this.$store.commit('setSelectedData', {value: item, type: 'manifest'})
      }
    }
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>

</style>
