import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'

import router from './router'
import store from './store'

import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

import JsonViewer from 'vue-json-viewer'

import {manifestServer, weighingServer, dataServer} from './api/config'

import {ApolloClient, HttpLink, InMemoryCache, ApolloLink} from 'apollo-boost'
import VueApollo from 'vue-apollo'

import {firebaseApp} from './helpers/firebase'

const httpLink = new HttpLink({ uri: 'https://api.skunkworks.ai/v1/graphql'})
const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem('token')

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      'X-Hasura-User-Uid': token ? token : '',
      'X-Hasura-User-Id': 'be3cfcff-cda0-4fb2-8381-a8f795ed5787'
    }
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});
const apolloClient = new ApolloClient({
  // You should use an absolute URL here
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

Vue.config.productionTip = false

Vue.prototype.$weight = weighingServer
Vue.prototype.$manifest = manifestServer
Vue.prototype.$server = dataServer

Vue.use(JsonViewer)
Vue.use(VueApollo)

new Vue({
  router,
  created() {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if(user) {
        // console.log(user)
        localStorage.setItem('token', user.uid)
        if(this.$router.currentRoute.path !== '/parcels') this.$router.push('/parcels')
      } else {
        localStorage.removeItem('token')
        this.$router.push('/auth')
      }
    });
  },
  store,
  vuetify,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
