import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
/*import store from '../store/'*/

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: 'parcels'
  },
  /*{
    path: '/transactions',
    name: 'Transactions',
    component: () => import(/!* webpackChunkName: "transactions" *!/ '../views/Transactions.vue')
  },*/
  /*{
    path: '/manifests',
    name: 'Manifests',
    component: () => import(/!* webpackChunkName: "manifests" *!/ '../views/Manifests.vue')
  },*/
  {
    path: '/parcels',
    name: 'Parcels',
    component: () => import(/* webpackChunkName: "parcel" */ '../views/Parcels.vue')
  },
  {
    path: '/cn22',
    name: 'CN22',
    component: () => import(/* webpackChunkName: "cn22" */ '../views/CN22.vue')
  },
  {
    path: '/cn22/scan',
    name: 'ScanCN22',
    component: () => import(/* webpackChunkName: "cn22-scan" */ '../views/ScanCN22.vue')
  },
  {
    path: '/app',
    name: 'Online App',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "online-app" */ '../views/OnlineApp.vue')
  },
  {
    path: '/auth',
    name: 'Auth',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue')
  },
  {
    path: '*',
    name: 'Page not Found',
    component: () => import(/* webpackChunkName: "online-app" */ '../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/*router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.user.loggedIn) {
      next({
        path: '/login',
        query: {redirect: to.fullPath}
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})*/

export default router
